<template>
  <div class="header" @click="toStart()">
    <sc-logo :logo="true"/>

    <transition name="fade-in">
      <div class="connected" v-if="connected" @click.stop>
        <div class="text">
          connected
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ScLogo from "@/components/sc-logo";

export default {
  name: "bf-header",

  props: {
    connected: {
      default: false,
    },
  },

  components: {ScLogo},
  methods: {
    toStart() {
      this.$router.push({path: '/', query: {}})
    }
  }
}
</script>

<style scoped lang="scss">

.header {
  display: flex;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: $color-background;
  box-sizing: border-box;
  padding: 2em 1.5em 3em;
}

.fade-in-enter-active {
  transition: all 0.4s ease-in-out;

  .text {
    transition: all 0.5s ease-in-out;
  }
}

.fade-in-enter .text {
  opacity: 0;
}

.connected {
  background: $color-primary;
  color: #fff;
  font-weight: 700;

  text-transform: uppercase;
  animation: appear 0.5s ease-in-out 1.5s forwards;

  position: fixed;
  top: 5em;
  height: calc(100% - 4em);
  width: 101%;
  right: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  .text {
    box-sizing: border-box;
    animation: pulse 0.5s ease-in-out 1.5s forwards;
    font-size: 1.8em;
    text-align: center;
  }
}

@keyframes appear {
  60% {
    border-radius: 0;
  }
  100% {
    width: 9em;
    top: 4.5em;
    height: 1.5em;
    right: 1.25em;
    border-radius: 10em;
  }
}

@keyframes pulse {
  100% {
    font-size: 0.7em;
  }
}
</style>