<template>
    <div class="root">
        <notification-panel></notification-panel>
        <bf-header/>
        <router-view></router-view>
    </div>
</template>

<script>
import BfHeader from "../components/bf-header";
export default {
  name: 'root',
  components: {BfHeader},
  mounted() {
    if (process.env.VUE_APP_TEST_MODE === 'true') {
      return
    }

    if (this.$route.query['ci'] && this.$route.query['si'] && this.$route.query['lang']) {
      this.$router.push({
        path: '/select',
        query: {
          lang: this.$route.query['lang'],
          ci: this.$route.query['ci'],
          si: this.$route.query['si']
        }
      })
    } else {
      this.$router.push('/').catch(err => {})
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap');
@import '@/design/_reset';
@import '@/design/_globals';
</style>

<style scoped lang="scss">

.root {
   
    height: 100%;
}
</style>