<template>
  <div class="phone">

    <bf-header :connected="connected"/>


    <div class="content" v-if="connected && !selected">

      <sc-categories :media="media" :categories="categories" v-if="!category"></sc-categories>

      <sc-category v-if="categoryItem" :category="categoryItem" @select="select"></sc-category>
    </div>

    <div class="item-container" v-if="connected && selected">
      <sc-item :key="selected.id"
               :item="selected"
               :playing="playing"
               :playingItem="playingItem"
               @playpause="playpause"
               @stop="stop"
               @swipe="swipe"
               @mouse="mouse"
      ></sc-item>
    </div>

    <div class="control-bar">
                  <div class="buttons-spacer" v-if="playingItem"></div>
                  <div class="buttons-spacer"></div>
      <div class="buttons-container">

        <transition name="playing-item">
          <div class="current-playing" v-if="playingItem" @click="select(playingItem)">
            <div class="current-name">
              {{ getMetadataValue(playingItem, 'MediaItem.name', playingItem.name) }}
            </div>
            <template v-if="playingItem.type !== 'scala_script'">
              <div class="times">
                <div class="time-current">{{ timePlayed | secondsToMinutes }}</div>
                <div class="duration-bar" :key="playingItem.id">
                  <div class="duration-bar-inner" style="width:0%;"
                       :style="{animationDuration:( playingItem.duration + 'ms'), animationDelay: playAnimationOffset + 'ms'}"></div>
                </div>
                <div class="time-total">{{ playingItem.duration | milisecondsToMinutes }}</div>
              </div>
            </template>
          </div>
        </transition>

        <div class="buttons">
          <div class="controls">
            <div class="control-button previous" @click="toPrevious()" :class="{disabled:!hasPrevious}">
              <div class="icon">
                <awesome-icon icon="step-backward"></awesome-icon>
              </div>
            </div>
            <div class="control-button play" @click="playpause((playingItem)?playingItem:selected)"
                 :class="{playing:playingItem,disabled: !selected && !playingItem}">
              <div class="icon">
                <awesome-icon icon="play"
                              v-if="!playingItem"></awesome-icon>
                <awesome-icon icon="stop"
                              v-if="playingItem"></awesome-icon>
              </div>
            </div>
            <div class="control-button next" @click="toNext()" :class="{disabled:!hasNext}">
              <div class="icon">
                <awesome-icon icon="step-forward"></awesome-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import BfHeader from "@/components/bf-header";
import ScCategories from "@/views/components/sc-categories";
import ScCategory from "@/views/components/sc-category";
import ScItem from "@/views/components/sc-item";

export default {
  name: "user-select",
  components: {ScItem, ScCategory, ScCategories, BfHeader},
  data() {
    return {

      socket: null,
      socket_timeout: null,

      connected: false,

      countdownInterval: null,

      playing: 0,
      playedTime: 0,
      playingInterval: null,
      playAnimationOffset: 500,

      media: [],

      ci: null,
      si: null,
      lang: null,
      override: false,

    }
  },

  filters: {
    secondsToMinutes(val) {
      return Math.floor(val / 60) + ':' + ((val % 60 < 10) ? '0' : '') + val % 60;
    },
    milisecondsToMinutes(val) {
      val = val / 1000
      return Math.floor(val / 60) + ':' + ((val % 60 < 10) ? '0' : '') + Math.round(val % 60);
    }
  },

  computed: {

    categories() {
      return [
        {
          id: 1, name: 'all', items: this.media
        },
        {
          id: 2, name: 'videos', items: this.media.filter(x => x.type === 'video')
        },
        {
          id: 6, name: 'images', items: this.media.filter(x => x.type === 'image')
        },
        // {
        //   id: 3,
        //   name: 'interactive',
        //   items: this.media.filter(x => this.getMetadataValue(x, 'MediaItem.category') === 'interactive')
        // },
        // {
        //   id: 4, name: 'dynamic', items: this.media.filter(x => {
        //     return x.type === 'message' || x.type === 'scala_script'
        //   })
        // },
        // {
        //   id: 5,
        //   name: 'games',
        //   items: this.media.filter(x => this.getMetadataValue(x, 'MediaItem.category') === 'games')
        // },
      ]
    },

    filteredMedia() {
      return this.categoryItem ? this.categoryItem.items : this.media;
    },

    selected() {
      if (this.$route.query.item) {
        return this.media.find(x => x.id === parseInt(this.$route.query.item))
      }
    },

    playingItem() {
      if (this.playing !== 0) {
        let item = this.media.find(x => x.id === parseInt(this.playing))
        if (item) {
          return item;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    timePlayed() {
      return this.playedTime;
    },

    category() {
      if (this.$route.query.category) {
        return parseInt(this.$route.query.category)
      }
    },

    categoryItem() {
      return this.categories.find(x => x.id === parseInt(this.category));
    },

    hasNext() {
      if (!this.playingItem) {
        return false
      }
      let currentIndex = this.filteredMedia.findIndex(x => x.id === this.playingItem.id)
      if (currentIndex === -1) {
        return false;
      } else {
        return !!this.filteredMedia[currentIndex + 1]
      }
    },

    hasPrevious() {
      if (!this.playingItem) {
        return false
      }
      let currentIndex = this.filteredMedia.findIndex(x => x.id === this.playingItem.id)
      if (currentIndex === -1) {
        return false;
      } else {
        return !!this.filteredMedia[currentIndex - 1]
      }
    }
  },

  mounted() {
    if (process.env.VUE_APP_TEST_MODE === 'true') {

      this.connected = true;
      this.media = [
        {
          "id": 10,
          "type": "video",
          "name": "Distance  - landscape.mp4",
          "lastModified": "2021-05-31 12:41:34",
          "duration": "6058",
          "metadata": "[{\"id\": 11464443, \"value\": \"Keep your distance\", \"metadata\": {\"id\": 11464442, \"name\": \"MediaItem.name\", \"order\": 1, \"datatype\": \"STRING\", \"valueType\": \"ANY\"}}]"
        }, {
          "id": 11,
          "type": "video",
          "name": "Keyboard - Landscape.mp4",
          "lastModified": "2020-06-18 10:52:10",
          "duration": "8021",
          "metadata": null
        }, {
          "id": 12,
          "type": "video",
          "name": "Ventilation - portrait.mp4",
          "lastModified": "2020-06-18 10:52:17",
          "duration": "7082",
          "metadata": null
        }, {
          "id": 13,
          "type": "image",
          "name": "Washhands landscape.jpg",
          "lastModified": "2020-06-18 10:52:21",
          "duration": "7061",
          "metadata": null
        }]

      return
    }

    if (this.$route.query['ci'] && this.$route.query['si'] && this.$route.query['lang']) {
      // good to go chief

      this.ci = this.$route.query['ci'];
      this.si = this.$route.query['si'];
      this.lang = this.$route.query['lang'];
      if (this.$route.query['override']) {
        this.override = true;
      }

      this.startWebsocket();
      let cat = (this.ci > 1) ? this.ci : '';
      this.$router.push({query: {category: cat}})

    } else {
      // Missing parameters
      this.$router.push('/')
    }
  },

  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
    }
    setTimeout(() => {
      this.socket = null;
      clearTimeout(this.socket_timeout);
    }, 1000)

    clearInterval(this.playingInterval);
  },

  methods: {

    back() {
      this.$router.push({query: {category: this.category}})
    },

    select(item, replace = false) {

      if (this.playingItem && !this.$route.query.item) {
        this.playAnimationOffset = (this.playedTime * 1000) * -1
      }

      if (replace) {
        this.$router.replace({query: {item: item.id, category: this.category}}).catch(err => {
        })
      } else {
        this.$router.push({query: {item: item.id, category: this.category}}).catch(err => {
        })
      }
      window.scrollTo(0, 0)
    },

    getMedia() {
      axios.get(process.env.VUE_APP_URL_API + `/api/sessions/${this.si}/get-media`)
          .then(res => {
            this.media = res.data;
          })
          .catch(err => {
            if (process.env.NODE_ENV === 'development') {
              this.$notify.error(err.response.message)
            }
            this.$router.push('/')
          })
    },

    playTimer() {
      clearInterval(this.playingInterval);
      this.playedTime = 0;
      this.playAnimationOffset = 500;

      if (!this.playingItem || this.playingItem.type === 'scala_script') {
        return
      }
      this.playingInterval = setInterval(() => {
        if (this.playedTime < Math.floor((this.playingItem.duration / 1000))) {
          this.playedTime++;
        } else {
          clearInterval(this.playingInterval)
          // this.playing = 0;
        }
      }, 1000)
    },

    stop(item) {
      if (this.socket) {
        let payload = {
          type: 'stopVideo',
          data: {
            media_id: item.id
          }
        }
        this.playing = 0;
        this.playedTime = 0;
        this.playAnimationOffset = 500;
        clearInterval(this.playingInterval);
        this.socket.send(JSON.stringify(payload))
      }
    },

    playpause(item) {
      if (this.socket || process.env.VUE_APP_TEST_MODE === 'true') {
        let payload;
        if (this.playing !== item.id) {
          payload = {
            type: 'playVideo',
            data: {
              media_id: item.id
            }
          }
          this.playing = item.id;
          this.playTimer();

        } else {
          payload = {
            type: 'stopVideo',
            data: {
              media_id: item.id
            }
          }
          this.playing = 0;
          this.playedTime = 0;
          this.playAnimationOffset = 500;
          clearInterval(this.playingInterval);
        }
        if (process.env.VUE_APP_TEST_MODE !== 'true') {
          this.socket.send(JSON.stringify(payload))
        }
      }
    },

    swipe(dir) {
      if (this.socket) {
        let payload = {
          type: 'motion',
          data: {
            type: 'swipe',
            direction: dir
          }
        }
        this.socket.send(JSON.stringify(payload))
      }
    },

    mouse(e) {
      if (this.socket) {
        let payload = {
          type: 'motion',
          data: {
            type: 'mouse',
            ...e
          }
        }
        this.socket.send(JSON.stringify(payload))
      }
    },

    toNext() {
      if (!this.playingItem) return
      let currentIndex = this.filteredMedia.findIndex(x => x.id === this.playingItem.id)
      if (currentIndex !== -1) {
        if (this.filteredMedia[currentIndex + 1]) {
          this.playpause(this.filteredMedia[currentIndex + 1])
          this.select(this.filteredMedia[currentIndex + 1], true)
        }
      }
    },

    toPrevious() {
      if (!this.playingItem) return
      let currentIndex = this.filteredMedia.findIndex(x => x.id === this.playingItem.id)
      if (currentIndex !== -1) {
        if (this.filteredMedia[currentIndex - 1]) {
          this.playpause(this.filteredMedia[currentIndex - 1], true)
          this.select(this.filteredMedia[currentIndex - 1], true)
        }
      }
    },

    videoStopped(video) {
      if (this.playingItem && (parseInt(video) === this.playingItem['id'] || parseInt(video) === 0)) {
        this.playing = 0;
        this.playedTime = 0;
        this.playAnimationOffset = 500;
        clearInterval(this.playingInterval);
      }
    },

    getMetadataValue(media, name, placeholder = 'none') {
      if (media.metadata === null) {
        return placeholder
      }

      let meta = JSON.parse(media.metadata);

      let metadata = meta.find(x => x['metadata']['name'] === name)
      if (!metadata) {
        return placeholder
      }

      // String Metadata
      if (metadata.metadata.valueType === 'ANY' && metadata.metadata.datatype === 'STRING') {
        return metadata.value
      }

      // Integer Metadata
      if (metadata.metadata.valueType === 'ANY' && metadata.metadata.datatype === 'INTEGER') {
        return metadata.value
      }

      // Picklist Metadata
      if (metadata.metadata.valueType === 'PICKLIST' && metadata.metadata.datatype === 'STRING') {
        if (!metadata.metadata.predefinedValues) {
          return placeholder;
        }
        let val = metadata.metadata.predefinedValues.find(x => x.id === parseInt(metadata.value))
        if (!val) {
          return placeholder;
        } else {
          return val.value
        }
      }

    },

    startWebsocket() {
      if ("WebSocket" in window) {

        // open the websocket connection
        let url = process.env.VUE_APP_URL_SOCKET + '?type=user' + '&si=' + this.si;
        if (this.override) {
          url += '&ovr=1'
        }
        this.socket = new WebSocket(url);

        this.socket.onmessage = (evt) => {

          let message = JSON.parse(evt.data)
          // console.log('heard this message:', message);

          if (message.type === 'error') {
            this.$notify.error(message.data)
          }

          if (message.type === 'newUserConnected') {
            this.connected = true;
            this.getMedia();
          }

          if (message.type === 'overwriteSessionRequest') {
            this.$router.push({
              path: '/override',
              query: {
                lang: this.lang,
                ci: this.ci,
                si: this.si
              }
            })
          }

          if (message.type === 'videoStop') {
            this.videoStopped(message.data)
          }

          if (message.type === 'ping') {
            this.socket.send(JSON.stringify({type: 'pong'}))
          }

        };

        this.socket.onopen = (evt) => {
          console.log('open!')
        };

        this.socket.onclose = (e) => {
          console.log('Socket is closed');

          if (this.$route.path !== '/override') {
            this.$router.push('/').catch(err => {
            });
          }
        };
      } else {
        console.log("WebSocket NOT supported by your Browser!");
        this.$notify.error('Websockets not supported')
      }
    },

  }
}
</script>

<style scoped lang="scss">

.buttons-spacer {
  height: 82px;
}

.phone {
  position: relative;
  height: calc(100% - 82px);
  //height: 100%;

  .item-container, .content {
    position: relative;
    z-index: 1;
    top: 6em;
    left: 0;
    right: 0;
    padding-bottom:82px;
    overflow-y: auto;
  }

  .control-bar {
    position: relative;
    z-index: 3;
  }
}

.buttons-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 1.5em;

  .current-playing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FFF;
    color: $color-secondary;
    padding: 0 1em;
    height: 3em;
    box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.03);
    cursor: pointer;
    z-index: 1;

    &:active {
      background: #eee;
    }

    &.playing-item-enter-active, &.playing-item-leave-active {
      transition: transform 0.3s ease-in-out;
    }

    &.playing-item-enter, &.playing-item-leave-to {
      transform: translate3d(0, 100%, 0)
    }

    .current-name {
      font-size: 0.7em;
      font-weight: 600;
      line-height: 1em;
      height: 1em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0.2em;
      text-align: center;
    }

    .times {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .duration-bar {
        height: 0.3em;
        flex: 1;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 100px;
        overflow: hidden;
        position: relative;

        .duration-bar-inner {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background: rgb(67, 73, 108);
          border-radius: 0 100px 100px 0;
          animation-name: grow;
          animation-timing-function: linear;
          animation-fill-mode: forwards;

          @keyframes grow {


            to {
              width: 100%;
            }
          }
        }
      }

      .time-current, .time-total {
        width: 4em;
        font-size: 0.5em;
        font-weight: 800;
      }

      .time-total {
        text-align: right;
      }
    }

    .motion-controls {
      font-size: 0.5em;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .buttons {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
    background: #FFF;
    padding: 0.1em 1em;
    box-sizing: border-box;
    position: relative;
    z-index: 2;

    .controls {
      margin: auto;
      display: flex;
      max-width: 300px;
    }

    .control-button {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.3em;
      padding: 0.4em 0;
      cursor: pointer;
      transition: opacity .2s ease-in-out;

      &:active {
        .icon {
          transform: scale(0.9)
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.4;
      }

      .icon {
        width: 2.4em;
        height: 2.4em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10em;
        color: rgb(67, 73, 108);
      }

      &.play {

        .icon {
          background: #222;
          color: #fff;
        }

        &.playing {
          animation: spintowin .3s ease-in-out;
        }
      }
    }
  }
}

@keyframes spintowin {
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(180deg)
  }
}


</style>