<template>
  <div class="page">
<!--    <div class="header">-->
<!--      <sc-logo :logo="true"/>-->
<!--    </div>-->
    <div class="start-screen">
      <div class="widget">
        <div class="description">
          Scan a <span class="thicc">QR</span> code to connect
        </div>
        <div class="buttons">
          <div class="button" @click="$router.push('/scan')">
            Open Camera
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BfHeader from "@/components/bf-header";
import ScLogo from "@/components/sc-logo";

export default {
  name: "start",
  components: {ScLogo, BfHeader},
  data() {
    return {}
  },

  computed: {},

  mounted() {

  },

  methods: {}
}
</script>

<style scoped lang="scss">

.page {
  background-color: $color-background;
}

.header {
  background: $color-background;
  padding: 1em 0;
  font-size: 2em;
  //height: 72px;
  box-sizing: border-box;

}

.start-screen {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  //padding: 1em;
  font-size: 1.2em;

  .description {

  }

  .buttons {
    margin-top: 1em;
    text-align: center;
  }
}
</style>