<template>
    <div>
        <div class="header">
            <div class="text">Categories</div>
        </div>
        <div class="categories">
            <div class="category" v-for="category in categories"
                 :class="{active:parseInt(queryCategory) === category.id}"
                 @click="select(category.id)">
                <div class="icon">
                    <div class="small-icons" v-if="category.items.length > 3">
                        <div class="small-icon" v-for="(item,index) in category.items" v-if="index < 4"><img
                                :src="getVideoThumbnail(item)"/></div>
                    </div>
                    <div class="small-icons" v-else-if="category.items.length > 0">
                        <div class="large-icon"><img :src="getVideoThumbnail(category.items[0])"/></div>
                    </div>
                    <awesome-icon v-else :icon="['far','folder']"/>
                </div>
                <div class="info">
                    <div class="name">{{ category.name }}</div>
                    <div class="count">{{ category.items.length }} items</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-categories",

  props: {
    categories: {},
    media: {}
  },

  computed: {

    queryCategory() {
      return this.$route.query['category']
    },

  },

  methods: {
    items(category) {
      return this.media.filter(x => category.criteria(x))
    },

    select(category) {
      this.$router.push({query: {category: category}})
    },

    getVideoThumbnail(item) {
      return `${process.env.VUE_APP_URL_API}/thumbnails-media/${item.id}/thumb-${item.id}.png`;
    },
  }
}
</script>

<style scoped lang="scss">
.header {
    padding: 2em 1.5em 0.5em;

    .text {
        font-size: 1.5em;
        color: #000;
        font-weight: 600;
    }
}

.categories {
    padding: 1.5em 1.5em;

    .category {
        display: flex;
        margin-bottom: 1em;
        font-size: 1.5em;

        .icon {
            background: rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
            box-sizing: border-box;
            width: 3em;
            height: 3em;
            margin-right: 0.8em;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            overflow: hidden;

            .small-icons {
                display: flex;
                flex-wrap: wrap;

                .small-icon {
                    width: 1.5em;
                    height: 1.5em;
                }

                .large-icon {
                    width: 3em;
                    height: 3em;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .name {
                color: #000;
                text-transform: capitalize;
                font-weight: 600;
                font-size: 0.9em;
            }

            .count {
                font-size: 0.6em;
                color: #000;
                margin-top: 0.2em;
            }
        }
    }
}


</style>