<template>
  <div class="logo">
    <div class="image" v-if="logo">
      <img src="@/img/hm-logo.svg"/>
    </div>
    <div class="name">
      <div class="bottom">
        Director
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sc-logo",

  data() {
    return {
      appName: process.env.VUE_APP_NAME
    }
  },

  props: {
    logo: {
      default: false,
    }
  }
}
</script>

<style scoped lang="scss">

.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: inherit;

  img {
    height: 30px;
  }

  div {
    line-height: 1em;
    font-size: 1em;
  }

  .name {
    font-weight: 600;
    color: #222;
    letter-spacing: 0.02em;
    font-size: 1.5rem;

    .top {
      font-size: 0.7em;
      margin-bottom: 0.3em;
      font-weight: 700;
    }

    .bottom {
      font-size: 24pt;
    }
  }
}


</style>